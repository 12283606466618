const location = window.location;
const hostName = location.hostname;

const stageConfig = {
    base: 'https://3k6byjiqzg-vpce-0524cdd56e1e680a3.execute-api.us-east-1.amazonaws.com/v1/', //'http://localhost:3200/'
    baseCognito: 'https://stage-skyhawks-login.auth.us-east-1.amazoncognito.com/',
    clientId: '1a13q50gi7ut15nufat05gm6f0',
}

const testConfig = {
    base: 'https://ahfua0ex3i-vpce-0524cdd56e1e680a3.execute-api.us-east-1.amazonaws.com/v1/',
    baseCognito: 'https://test-skyhawks-login.auth.us-east-1.amazoncognito.com/',
    clientId: '64gshcid3r69qjsgvvm01i18b5',
}

const prdConfig = {
    base: 'https://10xzj7ppul-vpce-0524cdd56e1e680a3.execute-api.us-east-1.amazonaws.com/v1/',
    baseCognito: 'https://prd-skyhawks-login.auth.us-east-1.amazoncognito.com/',
    clientId: '2uv9kadf8q7c08qnplapl9p7vh',
}

const API_MAP = {
    'localhost': stageConfig,
    'stage.skyhawks.cloud': stageConfig,
    'test.skyhawks.cloud': testConfig,
    'skyhawks.cloud': prdConfig,
}

export const BaseAPI = API_MAP[hostName].base;
const TenantsAPI = `${BaseAPI}tenants`;
const TeamsAPI = `${BaseAPI}teams`;
const PipelinesAPI = `${BaseAPI}pipelines`;
const BricksAPI = `${BaseAPI}bricks`;
const RepositoriesAPI = `${BaseAPI}repositories`;

export const APIList = {
    login: `${BaseAPI}login`,

    teams: TeamsAPI,
    createTeam: TeamsAPI,
    deleteSingleTeam: `${TeamsAPI}/id`,
    deleteBulkTeams: TeamsAPI,
    updateTeam: `${TeamsAPI}`,

    tenants: TenantsAPI,
    createTenant: TenantsAPI,
    deleteSingleTenant: `${TenantsAPI}/id`,
    deleteBulkTenants: TenantsAPI,
    updateTenant: `${TenantsAPI}`,

    pipelines: PipelinesAPI,
    deleteBulkPipelines: PipelinesAPI,
    deleteSinglePipeline: `${PipelinesAPI}/id`,
    createPipeline: PipelinesAPI,
    updatePipeline: `${PipelinesAPI}`,

    bricks: BricksAPI,
    createBrick: BricksAPI,
    deleteBulkBricks: BricksAPI,
    deleteSingleBrick: `${BricksAPI}/id`,
    updateBrick: `${BricksAPI}`,

    repositories: RepositoriesAPI,
    deleteSingleRepository: `${RepositoriesAPI}/id`,
    deleteBulkRepository: RepositoriesAPI,
    loadRepositorySettings: `${BaseAPI}settings`,
    createRepository: RepositoriesAPI,
    updateRepository: `${RepositoriesAPI}`,
}

export const BaseCognitoAPI = API_MAP[hostName].baseCognito;
const BaseAuthApi = `${BaseCognitoAPI}oauth2/`;
export const AuthLogoutApi = `${BaseCognitoAPI}oauth2/revoke`
export const tokenApi = `${BaseAuthApi}token`;
export const CLIENT_ID = API_MAP[hostName].clientId;



export const AuthApi = {
    token: tokenApi,
    authorize: `${BaseAuthApi}authorize`,
    authorizeParams: {
        identity_provider: 'GESSO',
        response_type: 'CODE',
        client_id: CLIENT_ID,
        redirect_uri: `${location.protocol}//${location.hostname}${location.port ? `:${location.port}` : ''}/auth-callback`,
    }
}
